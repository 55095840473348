import React, { useState } from "react"

import Layout from "../templates/Page"
import SwiperList from "../components/ContentBuilder/SwiperList"
import Header from "../components/ContentBuilder/Header"
import OtherAvailableOptions from "../components/ContentBuilder/SwiperList/OtherAvailableOptions"
import Subscribe from "../components/Footer/subscribe"

const ProductPage = () => {
  const [isReadmore, setReadmore] = useState(true)
  const [isViewmore, setViewmore] = useState(true)

  return (
    <Layout hideHeader>
      <Header isLogin />
      <div className="container">
        <p>
          <a>Explore</a> > <a>Category</a> > <a>Sub Category</a>
        </p>
        <div className="d-none d-sm-flex justify-content-between align-items-center wrap-section mt-30 mb-30 border-bottom-cell">
          <h1 className="product-name-title">Blanco LINUSSA Kitchen Mixer</h1>
          <div className="d-flex align-items-center">
            <div className="w-100 mr-4">Add to board</div>
            <img src="/img/hearth.svg" />
          </div>
        </div>

        <div className="row wrap-section no-margin">
          <div className="col-sm-8 p-0 mt-5 mt-sm-0">
            <div className="landing-detail-image-wrap"></div>
          </div>
          <div className="col-sm-4 p-0">
            <div className="d-flex d-sm-none justify-content-between align-items-center mt-30 border-bottom-cell">
              <h1 className="product-name-title">Gregory Hills, NSW</h1>
              <div className="d-flex align-items-center">
                <div className="w-100 mr-4">Add to board</div>
                <img src="/img/hearth.svg" />
              </div>
            </div>

            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <h3 className="product-information">PRODUCT INFORMATION</h3>
            </div>
            <div className="border-bottom-cell">
              <h5 className="title-cell">ABOUT</h5>
              <p className="right-cell about-content ">
                The tap is designed to go hand-in-hand with a coloured sink from
                Blanco’s SILGRANIT range, but it will ultimately serve as a
                welcome addition to any space. A minimalist pin lever allows for
                effortless control of water flow, with the added benefit of
                reducing visual clutter. The 140-degree swivel spout and high
                clearance outlet will come in handy when washing larger items.
                The pull-out spray head gives you complete coverage over
                everything in your sink,
              </p>
              <div
                className="text-right cursor-pointer"
                onClick={() => setReadmore(!isReadmore)}
              >
                <a>{isReadmore ? "Read more" : "Read less"}</a>
              </div>
            </div>
            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <h5 className="title-cell">Brand</h5>
              <p className="right-cell">Blanco</p>
            </div>
            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <h5 className="title-cell">Cost over standard</h5>
              <p className="right-cell">$195.00</p>
            </div>

            <div className="d-flex justify-content-center align-items-center mt-30 mb-30">
              <button className="btn add-to-board-button">
                <img src="/img/hearth.svg" style={{ height: 30, width: 30 }} />
                <span className="ml-2">Add to board</span>
              </button>
            </div>

            <div className="text-center pt-3 pb-4">
              <a
                className="view-more cursor-pointer"
                onClick={() => setViewmore(!isViewmore)}
              >
                {isViewmore ? "View more" : "View less"}
              </a>
            </div>
            <div className="text-center cursor-pointer">
              <img src="/img/view-more.svg" style={{ height: 30, width: 30 }} />
            </div>
          </div>
        </div>
        <OtherAvailableOptions />
        <SwiperList title="Other land this home works with" navClass="land" />
        <SwiperList title="Other homes that fit this land" navClass="home" />
        <SwiperList title="Available facade options" navClass="facade" />
        <SwiperList title="Works with these styles" navClass="works" />
        <SwiperList title="Related items" navClass="related" />
      </div>
      <Subscribe></Subscribe>
    </Layout>
  )
}

export default ProductPage
